/* Center the content vertically and horizontally */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

.Welcome {
  height: auto;
}

/* Style the content div */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px; /* Adjust top margin as needed */
}



/* Style the intro section */
.intro {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin-top: 40px; /* Adjust the top margin as needed */
}

/* Style the intro content on the left */
.intro-content {
  text-align: center;/* Adjust the right margin between content and image */
  max-width: auto;
}

.welcome-title {
  font-size: 4rem !important;
  font-weight: bolder;
  margin-bottom: 2px;
  color: #13284c;
}

.description {
  font-size: 20px; 
  color: #13284c;
}

/* Style the big picture on the right */
.big-picture-container {
  text-align: right;
  width: 80%; /* Take up full available width */
}

/* Ensure the image takes up the available width and make it larger */
.big-picture {
  width: 100%; /* Takes up 100% of the available width */
  max-width: 900px; /* Set a maximum width for the image */
  height: auto; /* Maintain aspect ratio */
}




/* Style the new about section */
.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin-top: 40px; /* Adjust the top margin as needed */
}

.about-image-container {
  text-align: left;
  width: 60% !important; /* Set the width for the image container */
}

.about-image {
  width: 100%; /* Takes up 100% of the available width */
  max-width: 100%; /* Ensure the image doesn't exceed its natural size */
  height: auto; /* Maintain aspect ratio */
}

.about-content {
  text-align: left;
  width: 55%; /* Set the width for the content container */
  margin-bottom: 10px;
}

.about-title {
  font-size: 1.7rem !important; /* Adjust the font size as needed */
  margin-bottom: 10px;
  color: #13284c;
}

.feature-list {
  list-style-type: disc; /* Use disc style for bullets */
  padding-left: 20px; /* Adjust the space between bullet and text */
  font-size: 1rem; /* Adjust the font size for list items */
}

.feature-list li {
  margin-bottom: 10px;
}

.about-content h3 {
  font-size: 1.7rem !important; /* Adjust the font size for the content text */
}

.about-content p {
  font-size: 1rem !important; /* Adjust the font size for the content text */
}



  /* Style the buttons */
  .button-container {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .input {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    flex: 2;
    margin-right: 10px;
  }
  
  .button {
    background-color: #b9fa3d;
    color: #13284c;
    padding: 10px 20px;
    border: none;
    border-radius: 20px; /* Increased border-radius for more rounded corners */
    cursor: pointer;
    align-self: center; /* Center the button horizontally */
    transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
    text-decoration: none; /* Remove underlines */
  }
  
  .button:hover {
    background-color: #fff;
    color: #2e0078;
  }
  
  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1px; /* Adjust this value as needed */
  }
  
  .signup-text {
    color: #13284c;
  }
  
  .content {
    text-align: center;
    font-size: smaller;
  }
  
  .content p {
    margin-bottom: 3px; /* Adjust the bottom margin for each paragraph */
  }
  



  @media (max-width: 767px) {
    .intro {
      flex-direction: column;
      align-items: center; /* Center items for smaller screens */
    }
  
    .intro-content {
      text-align: center; /* Center-align text */
      max-width: 100%;
    }
  
    .big-picture-container {
      width: 100%;
    }
  
    .title {
      font-size: 36px; /* Adjusted font size for smaller screens */
    }
  
    .description {
      font-size: 16px; /* Adjusted font size for smaller screens */
    }
  
    .about {
      flex-direction: column;
      align-items: center; /* Center items */
    }
  
    .about-image-container,
    .about-content {
      width: 100%;
      text-align: center; /* Center-align text */
    }
  
    .about-content p {
      font-size: 13px; /* Font size adjusted for smaller screens */
    }
  }
  