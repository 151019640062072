/* LoadingSpinner.css */
.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 20px;
  }
  
  .loading-spinner svg {
    margin-right: 10px;
  }
  