/* Profile.css */
.profile-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to the top vertically */
  height: 100vh; /* 100% of the viewport height */
  margin-top: 20px; /* Adjust the top margin as needed */
}

.profile-info {
  border: 2px solid #ccc;
  padding: 20px;
  text-align: left;
  width: 700px; /* Adjust this to your desired width */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.profile-info h2 {
  text-align: center;
  color: #13284c; /* White color for the heading text */
  margin-bottom: 1px;
  margin-top: 20px;
  font-size: 40px !important;
}

.subscription-buttons {
  text-align: center; /* Center-align the buttons */
  margin-top: 20px; /* Add some top margin for spacing */
}

.subscribe-title {
  font-size: 1.5rem; /* Adjust the font size of the title */
  margin-bottom: 10px; /* Add bottom margin for spacing between title and buttons */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .profile-info {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .profile-info {
    width: 100%;
  }
}


/* Add additional styles as needed */
