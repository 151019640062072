/* saved-properties-page.css */

.saved-properties-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    margin-top: 20px;
  }
  
  .saved-properties-info {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .saved-properties-title {
    text-align: center;
    color: #13284c; /* White color for the heading text */
    margin-bottom: 20px;
    font-size: 40px !important;
  }
  
  .saved-properties-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .saved-property-item {
    position: relative;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #ddd; /* Add border */
    border-radius: 5px; /* Add border radius */
    background-color: #fff; /* Add background color */
  }

  .delete-property-button {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000000; /* Customize the text color as needed */
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 20px; /* Adjust the font size as needed */
  }
  
  /* Add margin between saved properties */
  .saved-property-item + .saved-property-item {
    margin-top: 15px;
  }

  .saved-property-item:hover {
    background-color: #B9FA3D; /* Change the color to your desired hover color */
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .saved-properties-info {
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .saved-properties-info {
      max-width: 100%;
    }
  }
  