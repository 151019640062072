.feedback {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  width: 100%;
  margin: 10px auto;
  margin-bottom: 50px;
}

.title {
  text-align: center;
  color: #13284c; /* White color for the heading text */
  margin-bottom: 20px;
  font-size: 40px !important;
}

.feedback form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #13284c;
  text-align: left;
}

.feedback textarea {
  height: 200px; /* Set a fixed height */
  min-height: 150px; /* Ensure the minimum height is 150px */
  resize: vertical; /* Allow vertical resizing */
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 500px;
  max-width: 600px;
  margin-bottom: 15px;
}

.feedback input[type="number"] {
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 600px;
  margin-bottom: 15px;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {

  .feedback textarea,
  .feedback input[type="number"] {
    font-size: 14px; /* Decrease input font size for smaller screens */
    width: 230px;
    margin-bottom: 10px; /* Decrease margin between inputs for smaller screens */
  }
}