.property-form {
  border: none;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Align form items vertically */
  align-items: center; /* Center form items horizontally */
  justify-content: center; /* Center form items vertically */
  max-width: 100%; /* Set a fixed width for the form container */
  width: 100%; /* Ensure the form container takes 100% of its parent's width */
  margin: 10px auto; /* Center the form horizontally */
  margin-bottom: 50px;
}

.row.g-3 {
  margin-bottom: 30px; /* Adjust the margin as needed */
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-gap: 10px; /* Gap between columns */
  margin-bottom: 20px;
}

.form-floating>.form-control, .form-floating>.form-select{
  border-radius: 20px;
}

.form-column {
  flex: 1; /* Make items take up equal space within the column */
}

.form-title {
  text-align: center;
  color: #13284c; /* White color for the heading text */
  margin-bottom: 1px;
  margin-top: 20px;
}

.form-subtitle {
  font-family: Arial, sans-serif;
  font-size: 0.9em;
  font-weight: lighter;
  margin-top: 2px;
  text-align: center;
  margin-bottom: 10px; /* Adjust margin for better spacing */
  color: #13284c; /* White color for the heading text */
}

.property-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #13284c;
  padding-right: 10px;
  text-align: left; /* Align labels to the right */
}

.property-form input {
  padding: 8px;
  margin-bottom: 10px; /* Adjust margin for better spacing */
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 10px;
}

.analyze-property-btn-container {
  text-align: center;
  margin-top: 15px; /* Adjust margin for better spacing */
}

.property-form button {
  background-color: #b9fa3d;
  color: #13284c;
  padding: 10px 20px;
  border: none;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  cursor: pointer;
  align-self: center; /* Center the button horizontally */
  transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
}

.property-form button:hover {
  background-color: #fff;
  color: #2e0078;
}

.subscription-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Set a high z-index value */
}

.subscription-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.auth-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Set a high z-index value */
}

.auth-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  button {
    margin-right: 5px; /* Adjust the value based on your preference */
    margin-left: 5px; /* Adjust the value based on your preference */
  }
}

.subscribe-button {
  background: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
.disclaimer {
  background-color: #f8f9fa; /* Light gray background color */
  padding: 20px 0; /* Adjust padding as needed */
  margin-top: 20px; /* Adjust top margin as needed */
  margin-bottom: 10px; /* Adjust bottom margin as needed */
}

.disclaimer h1 {
  font-size: 1.5rem; /* Set the heading font size */
  margin-bottom: 10px; /* Bottom margin for the heading */
}

.disclaimer p {
  font-size: 1rem; /* Set the paragraph font size */
  color: #333; /* Dark text color */
  margin-bottom: 10px; /* Bottom margin for paragraphs */
}



.form-floating.address-input-container label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #13284c;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s, padding 0.3s;
}

.form-floating.address-input-container.input-focused label {
  position: absolute;
  top: 30%;
  left: 10px;
  font-size: 0.8em;
  padding: 0 5px;
  transition: top 0.3s, font-size 0.3s; /* Add transition for smoother resizing */
}

.form-floating.address-input-container input {
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 100%; /* Adjust width to fill the container */
  margin-right: 10px;
  padding-top: 24px;
  padding-left: 10px;
}

.form-floating.address-input-container.input-focused input {
  width: 100%; /* Adjust width when input is focused */
  padding-left: 10px;
}

.form-floating.address-input-container input::placeholder {
  text-align: right; /* Center-align placeholders for valid input state */
  color: #af7dff; /* Light purple color for placeholders */
  transform: translateY(-40%);
}

.form-floating.address-input-container.suggestions-present label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #13284c;
  pointer-events: none;
}

.suggestions-container {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}



@media (max-width: 991px) {
  .property-form {
    padding-left: 20px; /* Adjust padding for smaller screens */
    padding-right: 20px;
  }

  .address-input-container input,
  .bed-bath-input-container input,
  .purchase-price-input-container input {
    max-width: 100%; /* Make inputs span full width on small screens */
  }
}

/* Center-align placeholders and set light purple color for form elements with floating labels */
.form-floating>.form-control::placeholder {
  text-align: right; /* Center-align placeholders */
  color: #af7dff; /* Light purple color for placeholders */
}

/* Additional styles for active and focus states */
.form-floating>.form-control:focus::placeholder {
  text-align: right; /* Center-align placeholders for focus state */
  color: #af7dff; /* Light purple color for placeholders */
}

.form-floating>.form-control:valid::placeholder {
  text-align: right; /* Center-align placeholders for valid input state */
  color: #af7dff; /* Light purple color for placeholders */
}

.form-floating>.form-control:invalid::placeholder {
  text-align: right; /* Center-align placeholders for invalid input state */
  color: #af7dff; /* Light purple color for placeholders */
}

