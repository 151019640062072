
/* Styles for smaller screens (mobile devices) */
@media (max-width: 991px) {
  .navbar {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
  }

  .navbar-toggler {
    margin-right: 10px;
  }

  .navbar-collapse {
    width: 100%;
    padding: 10px;
    background-color: #343a40; /* Set background color for the dropdown menu */
    z-index: 9999; /* Set a high z-index to overlay everything */
  }

  .navbar-brand img {
    margin-bottom: 10px; /* Add space below the logo on smaller screens */
    margin-right: 10px; /* Add margin to logo on larger screens */
    margin-left: 10px; /* Add margin to logo on larger screens */
  }

  .nav-link {
    width: 100%; /* Make links take up full width */
    margin-bottom: 1px; /* Add space between links */
    text-align: center; /* Align text to the left */
  }

}
