/* how-to-use-info-page.css */

.how-to-use-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  margin-top: 20px;
}

.how-to-use-info {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  color: #13284c; /* White color for the heading text */
  margin-bottom: 20px;
  font-size: 40px !important;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
}

h4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #333;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #555;
}

strong {
  font-weight: bold;
  color: #333;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .how-to-use-info {
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .how-to-use-info {
      max-width: 100%;
    }
  }