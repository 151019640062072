.details {
  background-color: #f8f6fb;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  padding: 20px;
  display: auto;/* Add some gap between columns */
}

.property-details {
  background-color: #f8f6fb;
  max-width: auto;
  margin: 0 auto;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  display: grid;
  grid-template-columns: 1fr 1fr; /* Create a two-column layout */
  grid-gap: 20px; /* Add some gap between columns */
}


.point {
  font-size: 40px;
}

.results-heading {
  text-align: center;
}

.save-button {
  text-align: center;
}

.left-column {
  grid-column: 1; /* Place the left column in the first column */
  /* You can add additional styling specific to the left column here */
}

.right-column {
  grid-column: 2; /* Place the right column in the second column */
  /* You can add additional styling specific to the right column here */
}

.property-info h2 {
  color: #13284C; /* Blue text color */
}

.property-info {
  background-color: #e8e1f4;
  border-radius: 20px;
  padding: 30px;
  margin-top: 10px;
}

.metrics-area {
  background-color: #e8e1f4;
  border-radius: 20px;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.metrics-title {
  font-size: 24px;
  font-weight:bold;
  margin-bottom: 20px;
  color: #13284C; /* Blue text color */
}

.metrics-category {
  padding: 2px;
  border-bottom: 1px solid #ccc; /* Add a border at the bottom */
}

.metrics-category h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #2E0078; /* Blue text color */
}

.metric {
  font-size: 14px;
  margin: 5px 0;
  color: #13284C; /* Blue text color */
  display: grid;
  grid-template-columns: 1fr 1fr; /* Equal columns for label and value */
  gap: 10px; /* Gap between label and value */
}

.metric-label {
  text-align: left; /* Align the label to the left */
}

.metric-value {
  text-align: right; /* Align the value to the right */
}

.metrics input {
  width: auto;
  padding: 7px;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  border: 1px solid #ccc;
  text-align: right; /* Align the text within the input to the right */
}

.circle {
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Should be equal to the width to create a circle */
  border-radius: 50%; /* This property creates the circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.instruction-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: blueviolet;
}

.update-button-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.details button {
  background-color: #B9FA3D;
  color: #13284C;
  padding: 10px 20px;
  border: none;
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  cursor: pointer;
  margin-top: 10px; /* Add some space between the inputs and the button */
  max-width: auto;
}

.details button:hover {
  background-color: #fff;
  color: #2E0078;
}

@media (max-width: 768px) {
  .details {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .property-details {
    grid-template-columns: 1fr; /* Change to a single column layout */
    grid-gap: 10px; /* Reduce gap between columns */
  }

  .right-column {
    grid-column: 1; /* Ensure right column appears below left column on smaller screens */
  }

  .metrics-area,
  .property-info {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .metrics-title {
    font-size: 20px; /* Decrease font size for smaller screens */
    margin-bottom: 15px; /* Adjust margin for smaller screens */
  }

  .metric {
    font-size: 12px; /* Decrease font size for smaller screens */
    margin: 3px 0; /* Adjust margin for smaller screens */
  }

  .metrics input {
    padding: 5px; /* Adjust input padding for smaller screens */
  }

  .circle {
    width: 100px; /* Adjust circle size for smaller screens */
    height: 100px; /* Should be equal to the width to create a circle */
    /* Your existing styles */
  }
}

.two-column-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px; /* Adjust the gap between columns as needed */
}

.two-column-metrics .metric-pair {
  display: flex;
  justify-content: space-between;
}

.two-column-metrics .metric {
  flex: 1;
}

/* Adjust the space above and below the button-div */
.button-div {
  margin-bottom: 10px; /* Adjust the bottom margin as needed */
}

