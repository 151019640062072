/* TenYearTable.css */

/* Style for the entire table container */
.hold-period-table {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
    border: 1px solid #ddd;
    overflow-x: auto; /* Enable horizontal scrolling for the table */
    max-width: 80%; /* Set a maximum width to avoid stretching too wide */
    margin: 0 auto; /* Center the table horizontally */
    text-align: center; /* Center align text within the table */
    max-width: auto;
  }
  
.unlevered {
  color: blueviolet;
}

.levered {
  color: blueviolet;
}

  /* Style for the table title */
  .table-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Style for the table */
  table {
    width: auto; /* Set width to auto so it takes the content width */
    border-collapse: collapse;
    border: 1px solid #ddd;
    table-layout: fixed; /* Fix the table layout */
    align-items: center;
  }
  
  /* Style for table headers (th) */
  table th {
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 10px;
  }
  
  /* Style for table data cells (td) */
  table td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  /* Style for odd rows (striped background) */
  table tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect for table rows */
  table tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Center align text in the first column */
  table th:first-child,
  table td:first-child {
    text-align: center;
    position: sticky;
    left: 0; /* Stick to the left when scrolling horizontally */
    z-index: 2; /* Ensure it appears above the content */
  }
  