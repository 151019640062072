/* login & Signup page style */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo-container {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 60px; /* Adjust the width as needed */
  height: 60px;
}

a,
button {
  all: unset;
  cursor: pointer;
}

.login img {
  width: 100%;
  height: 100%;
  display: block;
}

 .login {
  min-height: 100vh;
  background-color: #f5f9ff;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
} 

 .login .container {
  max-width: 1070px;
  width: 100%;
  display: flex;
  margin-inline: 1rem;
  justify-content: center;
}
.login .ana{
  color: #C7F861;
}

@media screen and (max-width: 60rem) {
  .login .container {
    width: 100%;
    margin: 0;
  }
}

.login .form-section {
  width: 500px;
  max-width: 100%;
  background-color: white;
  padding: 2.5rem 1rem;
  display: flex;
  align-items: center;
}

.login .form-wrapper {
  width: 75%;
  margin: 0 auto;
}

.login .header-container {
  width: 3rem;
  height: 3rem;
  margin-bottom: 2.2rem;
}

.login .form-wrapper > h2 {
  margin-bottom: 0.1rem;
}
.login .form-wrapper > p {
  font-size: .9rem;
  font-weight: 500;
  color: hsl(242, 18%, 64%);
  margin-bottom: 2rem;
}

.login .form-wrapper > p.instr{
    text-align: end;
    margin-bottom: 0px;
}
.login .form-wrapper > p.instr a{
    color: #C7F861;
}

.login .input-container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.login .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.login .form-group label {
  font-size: 1rem;
  font-weight: 600;
  color: #16164d;
}
.login .form-group input {
  font-size: 0.875rem;
  font-weight: 500;
  color: #2e0078;
  padding: 0.5rem;
  border: 2px solid #2e0078;
  border-radius: 10px;
}
.login .form-group input:focus {
  border: 2px solid #16164d;
}

.login .remember-forgot {
  display: flex;
  justify-content: right;
  font-size: 0.875rem;
  font-weight: 500;
  color: #2e0078;
}

.login .remember-me {
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 0.6rem;
}

.login input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  display: grid;
  place-content: center;
}

.login .remember-me input {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border: 1px solid #13284c;
  border-radius: 2px;
}

.login input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  background-color: white;
  transform: scale(0);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #13284c;
}

.login input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.login .remember-me input:checked {
  background: #13284c;
}

.login .remember-me label {
  color: #13284c;
  user-select: none;
}
.login .remember-forgot a {
  color: #13284c;
}
.login .form-wrapper > p.instr a:hover,
.login .remember-forgot a:hover {
  text-decoration: underline;
  text-decoration-color: #b9fa3d;
}

.login .login-btn,
.login .google-signin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  background: #b9fa3d;

  font-weight: 500;
  padding: 0.5rem 0;
  border-radius: 20px;
}

.login .login-btn {
  color: #13284c;
  background: #b9fa3d;
}
.login .login-btn:hover {
  background-color: #fff;
  color: #2e0078;
}

.login .or-divider {
  width: 100%;
  font-weight: 600;
  color: #13284c;
  margin-block: 1.5rem;
  text-align: center;
  position: relative;
}
.login .or-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 45%;
  background-color: #13284c;
}
.login .or-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 1px;
  width: 45%;
  background-color: #13284c;
}

.login .google-signin {
  border: 2px solid white;
}
.login .google-signin object {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 50rem) {
  .login .container {
    max-width: 80%;
    flex-direction: column;
    margin-inline: 0;
  }
  .login .form-section {
    width: 100%;
  }

  .login .form-section {
    flex: 1;
    padding: 1rem 1rem;
  }
  .login .header-container {
    margin-bottom: 1rem;
  }
  .login .form-wrapper > h2 {
    font-size: 1.25rem;
  }
  .login .form-wrapper > p {
    margin-bottom: 1rem;
  }
  .login .form-wrapper button {
    font-size: 0.875rem;
  }
  .login .or-divider {
    font-weight: 500;
    margin-block: 0.6rem;
  }
}

@media screen and (max-width: 36.25rem) {
  .login .container {
    max-width: 100%;
  }
  .login .form-wrapper {
    width: 100%;
  }
}




.back-to-top {
position: fixed;
display: none;
right: 30px;
bottom: 30px;
z-index: 99;
}

.fw-medium {
font-weight: 500;
}


/*** Spinner ***/
#spinner {
opacity: 0;
visibility: hidden;
transition: opacity .5s ease-out, visibility 0s linear .5s;
z-index: 99999;
}

#spinner.show {
transition: opacity .5s ease-out, visibility 0s linear 0s;
visibility: visible;
opacity: 1;
}


/*** Button ***/
.btn {
transition: .5s;
font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
color: var(--bs-light);
}

.btn-secondary,
.btn-outline-secondary:hover {
color: white;
}

.btn-square {
width: 38px;
height: 38px;
}

.btn-sm-square {
width: 32px;
height: 32px;
}

.btn-lg-square {
width: 48px;
height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
padding: 0;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50px;
font-weight: normal;
}

.icon-box-primary,
.icon-box-light {
position: relative;
padding: 0 0 10px 10px;
z-index: 1;
}

.icon-box-primary i,
.icon-box-light i {
font-size: 60px;
line-height: 0;
}

.icon-box-primary::before,
.icon-box-light::before {
position: absolute;
content: "";
width: 35px;
height: 35px;
left: 0;
bottom: 0;
border-radius: 35px;
transition: .5s;
z-index: -1;
}

/* .icon-box-primary::before {
background: var(--bs-primary);
}

.icon-box-light::before {
background: var(--bs-light);
} */
